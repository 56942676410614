.tableContent {
    .w200 {
        width: 200px;
        display: inline-block;
    }
    .wPrice {
        min-width: 70px;
        max-width: 100px;
        display: inline-block;
    }
    .btnRelative {
        float: right;
        position: relative;
    }
    .btnIcon {
        position: relative;
        left: -0.6rem;
    }
    tbody > tr:hover > td {
        background-color: #f9f0ff !important;
    }
    thead tr th:last-child::before {
        content: 'Tiendas';
    }
    tbody tr td:last-child button {
        margin-left: 1rem;
    }
    .columnMoney {
        text-align: right;
    }
    @media (max-width: 991px) {
        .scrollTable {
          overflow-x: scroll;
        }
    }

}