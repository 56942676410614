.AccountSettings {
  .userUrl {
    margin: 2rem 0;
  }
  .btnWhats {
    background-color: #33de53;
    color: white;
    margin-bottom: 1.5rem;
  }
  .btnWhats:hover {
    background-color: #67ff83;
    border-color: #67ff83;
  }
  .btnWhats:focus {
    background-color: #67ff83;
    border-color: #67ff83;
  }
  .btnSave,
  .btnWhats {
    width: 12.6rem;
  }
  .inputColor {
    // display: none;
    height: 2.35rem;
    width: 6rem;
  }
  .btnColor {
    height: 3rem;
    width: 3rem;
  }
  .btnSave {
    margin-top: 2rem;
  }

}