.tableContent {
    .w200 {
        width: 200px;
        display: inline-block;
    }
    .btnRelative {
        float: right;
        position: relative;
    }
    .btnIcon {
        position: relative;
        left: -0.6rem;
    }
    @media (max-width: 991px) {
        .scrollTable {
          overflow-x: scroll;
        }
    }
    tbody > tr:hover > td {
        background-color: #f9f0ff !important;
    }
}