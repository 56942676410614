.theHome{
  .appBar{
    position: fixed;
    z-index: 10;
    width: 100vw;
    border-bottom: 1px solid rgb(235, 237, 240);
    .title{
      width: 150px;
      height: 36px;
      object-fit: contain;
      @media (max-width: 767px) {
        margin-left: 10px;
      }
    }
    .itesaLogo{
      @media (max-width: 767px) {
        display: none;

      }
    }
    .hambButton{
      display: none;
      @media (max-width: 767px) {
        display: inline-block;
      }
    }
  }
  .content{
    text-align: left;
    padding: 24px 60px;
    // text-transform: capitalize;
    position: relative;
    margin-left: 200px;
    margin-top: 70.21px;
    @media (max-width: 767px) {
      margin-left: 0;
      padding: 24px 2%;
    }
    .breadcrumb{
      font-size: 2rem;
      font-weight: 500;
      margin-bottom: 1rem;
      text-transform: capitalize;
    }
    .newButton{
      position: absolute;
      top: 24px;
      right: 60px;
      font-weight: 600;
      @media (max-width: 767px) {
        position: relative;
        top: auto;
        right: auto;
        margin-top: 10px;
      }
    }
  }

}