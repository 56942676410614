    .userImg {
        width: 100%;
        padding-bottom: 0.5rem;
        margin: auto;
    }
    .userImgContainer {
        min-height: 7rem;
        max-height: 20rem;
        display: flex;
        background: #ffff;
    }
    .placeholder {
        margin: auto;
        padding: 1.5rem;
        color: #9146f7c2;
    }
